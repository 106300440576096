// Routers.js
import { Route, Routes } from "react-router-dom";
import Admin from "./admin/Admin";
import RouteScroll from "./utils/RouteScroll";
import Authentication from "./protected/Protected";
import Dashboard from "./admin/adminComponents/Dashboard";
import { AdminOffice } from "./admin/adminComponents/office/Office";
import AdminUsers from "./admin/adminComponents/users/Users";
import { AdminTournaments } from "./admin/adminComponents/tournaments/Tournaments";
import { AdminTournamentsSingle } from "./admin/adminComponents/tournaments/TournamentsSingle";
import { useEffect, useState } from "react";
import LazyLoading from "./components/LazyLoad";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentUser } from "./store/usersApi/usersThunks";
import SignIn from "./pages/auth/Login";
import AdminJobs from "./admin/adminComponents/jobs/QuickJobs";
import AdminStandartJobs from "./admin/adminComponents/jobs/StandartJobs";
import AdminDiscovers from "./admin/adminComponents/discovers/Discovers";

function Routers() {
  const { changeRole } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch, changeRole]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <LazyLoading />;
  }

  return (
    <div>
      <RouteScroll />
      <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route element={<Authentication />}>
          <Route path="/*" element={<Admin />} />
          <Route path="/admin" element={<Admin />}>
            <Route path="" element={<Dashboard />} />
            <Route path="office" element={<AdminOffice />} />
            <Route path="users" element={<AdminUsers />} />
            <Route path="tournaments" element={<AdminTournaments />} />
            <Route path="jobs" element={<AdminJobs />} />
            <Route path="jobs/standart" element={<AdminStandartJobs />} />
            <Route path="discovers" element={<AdminDiscovers />} />
            <Route
              path="tournaments/:id"
              element={<AdminTournamentsSingle />}
            />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default Routers;
