import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createDiscover,
  fetchDiscovers,
  fetchTags,
} from "../../../store/discoverApi/discoverThunks";
import toast from "react-hot-toast";

const AddDiscoverForm = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const {
    tags,
    status: tagStatus,
    error: tagError,
  } = useSelector((state) => state.discover);

  const [formData, setFormData] = useState({
    title_en: "Sample Title in English",
    title_ru: "Пример заголовка на русском",
    title_zh: "示例中文标题",
    title_uz: "Namuna Oʻzbekcha sarlavhasi",
    description_en: "Sample description in English.",
    description_ru: "Пример описания на русском.",
    description_zh: "示例中文描述。",
    description_uz: "Namuna Oʻzbekcha tavsifi.",
    tags: [],
    countryCode: "US",
    location: "New York",
    languages: ["en", "ru", "zh", "uz"],
    image: null,
  });

  // Fetch tags on mount
  useEffect(() => {
    if (tagStatus === "idle") {
      dispatch(fetchTags());
    }
  }, [dispatch, tagStatus]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle image file input
  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  // Handle multiple tag selection
  const handleTagChange = (e) => {
    const value = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setFormData({ ...formData, tags: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !formData.title_en ||
      !formData.description_en ||
      formData.tags.length === 0
    ) {
      toast.error(
        "Please fill out all required fields and select at least one tag."
      );
      return;
    }

    const data = new FormData();

    const title = {
      en: formData.title_en,
      ru: formData.title_ru,
      zh: formData.title_zh,
      uz: formData.title_uz,
    };

    const description = {
      en: formData.description_en,
      ru: formData.description_ru,
      zh: formData.description_zh,
      uz: formData.description_uz,
    };

    data.append("title", JSON.stringify(title));
    data.append("description", JSON.stringify(description));
    data.append("tags", JSON.stringify(formData.tags));
    data.append("countryCode", formData.countryCode);
    data.append("location", formData.location);
    data.append("languages", JSON.stringify(formData.languages));
    if (formData.image) {
      data.append("image", formData.image);
    }

    dispatch(createDiscover(data)).then((res) => {
      if (res.payload) {
        toast.success("Discover item added successfully!");
        dispatch(fetchDiscovers());
        setShowModal(false);
      } else {
        toast.error("Failed to add discover item!");
      }
    });
  };

  return (
    <div className="fixed inset-0 flex justify-center bg-black bg-opacity-50 z-[1000] overflow-scroll">
      <div className="bg-white  p-5 rounded-lg w-full max-w-lg relative border-2 border-red-600 min-h-[110vh] my-10">
        {/* Close Button */}
        <button
          onClick={() => setShowModal(false)}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <h2 className="text-xl font-bold mb-4">Add New Discover Item</h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Title and Description Fields */}
          {["en", "ru", "zh", "uz"].map((lang) => (
            <div key={lang}>
              <label className="block text-sm font-medium text-gray-700">
                Title ({lang}) <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name={`title_${lang}`}
                value={formData[`title_${lang}`] || ""}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required={lang === "en"}
              />

              <label className="block text-sm font-medium text-gray-700 mt-2">
                Description ({lang}) <span className="text-red-500">*</span>
              </label>
              <textarea
                name={`description_${lang}`}
                value={formData[`description_${lang}`] || ""}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required={lang === "en"}
              />
            </div>
          ))}

          {/* Tags */}

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Tags <span className="text-red-500">*</span>
            </label>
            {tagStatus === "loading" ? (
              <p>Loading tags...</p>
            ) : tagError ? (
              <p className="text-red-500">Failed to load tags.</p>
            ) : tags.length === 0 ? (
              <p>No tags available.</p>
            ) : (
              <div className="grid grid-cols-2 gap-2">
                {tags.map((tag) => (
                  <label key={tag._id} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      value={tag._id}
                      checked={formData.tags.includes(tag._id)}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevState) => {
                          const updatedTags = prevState.tags.includes(value)
                            ? prevState.tags.filter((tagId) => tagId !== value)
                            : [...prevState.tags, value];
                          return { ...prevState, tags: updatedTags };
                        });
                      }}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="text-sm text-gray-700">
                      {tag.keyText[0]?.translations?.en || "Unknown Tag"}
                    </span>
                  </label>
                ))}
              </div>
            )}
          </div>

          {/* Other Fields */}
          <label className="block text-sm font-medium text-gray-700">
            Country Code
          </label>
          <input
            type="text"
            name="countryCode"
            value={formData.countryCode}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            required
          />

          <label className="block text-sm font-medium text-gray-700">
            Location
          </label>
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />

          <label className="block text-sm font-medium text-gray-700">
            Image
          </label>
          <input
            type="file"
            name="image"
            onChange={handleImageChange}
            className="mt-1 block w-full"
          />

          {/* Form Actions */}
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={() => setShowModal(false)}
              className="bg-gray-500 text-white px-4 py-2 rounded-lg"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            >
              Save
            </button>
          </div>
        </form>

        {/* Reload Tags Button */}
        {/* <div className="mt-4">
          <button
            onClick={() => dispatch(fetchTags())}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
          >
            Reload Tags
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default AddDiscoverForm;
