import { createSlice } from "@reduxjs/toolkit";
import {
  fetchDiscovers,
  fetchDiscoverById,
  createDiscover,
  updateDiscover,
  deleteDiscover,
  searchDiscovers,
  fetchTags,
  createTag,
  updateTag,
  deleteTag,
  searchTags,
} from "./discoverThunks";

const initialState = {
  discovers: [], // List of discover items
  discover: null, // Single discover item details
  tags: [], // List of tags
  tag: null, // Single tag details
  searchResults: [], // Results of search queries
  tagSearchResults: [], // Results of tag search queries
  totalCount: 0, // Total number of discovers
  totalPages: 0, // Total number of pages
  currentPage: 1, // Current pagination page
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  tagStatus: "idle", // Status for tag operations
  error: null, // Error messages
};

const discoverSlice = createSlice({
  name: "discover",
  initialState,
  reducers: {
    clearSearchResults(state) {
      state.searchResults = [];
    },
    clearTagSearchResults(state) {
      state.tagSearchResults = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all discovers
      .addCase(fetchDiscovers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDiscovers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.discovers = action.payload.discovers;
        state.totalCount = action.payload.totalCount;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchDiscovers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch a single discover
      .addCase(fetchDiscoverById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDiscoverById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.discover = action.payload;
      })
      .addCase(fetchDiscoverById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch tags
      .addCase(fetchTags.pending, (state) => {
        state.tagStatus = "loading";
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.tagStatus = "succeeded";
        state.tags = action.payload;
      })
      .addCase(fetchTags.rejected, (state, action) => {
        state.tagStatus = "failed";
        state.error = action.payload;
      })
      // Create a tag
      .addCase(createTag.pending, (state) => {
        state.tagStatus = "loading";
      })
      .addCase(createTag.fulfilled, (state, action) => {
        state.tagStatus = "succeeded";
        state.tags.unshift(action.payload);
      })
      .addCase(createTag.rejected, (state, action) => {
        state.tagStatus = "failed";
        state.error = action.payload;
      })
      // Update a tag
      .addCase(updateTag.pending, (state) => {
        state.tagStatus = "loading";
      })
      .addCase(updateTag.fulfilled, (state, action) => {
        state.tagStatus = "succeeded";
        const index = state.tags.findIndex((tag) => tag._id === action.payload._id);
        if (index !== -1) {
          state.tags[index] = action.payload;
        }
      })
      .addCase(updateTag.rejected, (state, action) => {
        state.tagStatus = "failed";
        state.error = action.payload;
      })
      // Delete a tag
      .addCase(deleteTag.pending, (state) => {
        state.tagStatus = "loading";
      })
      .addCase(deleteTag.fulfilled, (state, action) => {
        state.tagStatus = "succeeded";
        state.tags = state.tags.filter((tag) => tag._id !== action.payload);
      })
      .addCase(deleteTag.rejected, (state, action) => {
        state.tagStatus = "failed";
        state.error = action.payload;
      })
      // Search tags
      .addCase(searchTags.pending, (state) => {
        state.tagStatus = "loading";
      })
      .addCase(searchTags.fulfilled, (state, action) => {
        state.tagStatus = "succeeded";
        state.tagSearchResults = action.payload;
      })
      .addCase(searchTags.rejected, (state, action) => {
        state.tagStatus = "failed";
        state.error = action.payload;
      })
      // Create a new discover
      .addCase(createDiscover.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createDiscover.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.discovers.unshift(action.payload);
      })
      .addCase(createDiscover.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Update a discover
      .addCase(updateDiscover.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateDiscover.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.discovers.findIndex((item) => item._id === action.payload._id);
        if (index !== -1) {
          state.discovers[index] = action.payload;
        }
      })
      .addCase(updateDiscover.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Delete a discover
      .addCase(deleteDiscover.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteDiscover.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.discovers = state.discovers.filter((item) => item._id !== action.payload);
      })
      .addCase(deleteDiscover.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Search discovers
      .addCase(searchDiscovers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchDiscovers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.searchResults = action.payload.discovers;
        state.totalCount = action.payload.totalCount;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(searchDiscovers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearSearchResults, clearTagSearchResults } = discoverSlice.actions;

export default discoverSlice.reducer;
