import React, { useState } from "react";
import toast from "react-hot-toast";

const DiscoverTags = ({ tags, onAddTag, onDeleteTag, setShowModal }) => {
  const [formData, setFormData] = useState({
    keyText_en: "",
    keyText_ru: "",
    keyText_zh: "",
    keyText_uz: "",
    countryCode: "",
    languages: ["en", "ru", "zh", "uz"], // Default supported languages
  });

  // Handle input change for form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle adding a new tag
  const handleAddTag = (e) => {
    e.preventDefault();

    if (!formData.countryCode || !formData.keyText_en) {
      toast.error("Country code and English key text are required!");
      return;
    }

    const newTag = {
      keyText: {
        en: formData.keyText_en,
        ru: formData.keyText_ru,
        zh: formData.keyText_zh,
        uz: formData.keyText_uz,
      },
      countryCode: formData.countryCode,
      languages: formData.languages,
    };

    onAddTag(newTag); // Call parent handler to add the tag
    setFormData({
      keyText_en: "",
      keyText_ru: "",
      keyText_zh: "",
      keyText_uz: "",
      countryCode: "",
      languages: ["en", "ru", "zh", "uz"],
    });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-5 rounded-lg w-full max-w-lg relative">
        {/* Close Button */}
        <button
          onClick={() => setShowModal(false)}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <h2 className="text-xl font-bold mb-4">Manage Tags</h2>

        {/* Tag Form */}
        <form onSubmit={handleAddTag} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Key Text (English) <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="keyText_en"
              value={formData.keyText_en}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              required
            />
          </div>

          {["ru", "zh", "uz"].map((lang) => (
            <div key={lang}>
              <label className="block text-sm font-medium text-gray-700">
                Key Text ({lang.toUpperCase()})
              </label>
              <input
                type="text"
                name={`keyText_${lang}`}
                value={formData[`keyText_${lang}`]}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>
          ))}

          <label className="block text-sm font-medium text-gray-700">
            Country Code <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="countryCode"
            value={formData.countryCode}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            required
          />

          <div className="flex justify-end space-x-2">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            >
              Add Tag
            </button>
          </div>
        </form>

        {/* Tag List */}
        <div className="mt-6">
          <h3 className="text-lg font-bold mb-2">Existing Tags</h3>
          {tags.length > 0 ? (
            <ul className="space-y-2">
              {tags.map((tag) => (
                <li
                  key={tag._id}
                  className="bg-gray-100 p-2 rounded-lg flex justify-between items-center"
                >
                  <span>
                    {tag.keyText[0]?.translations?.en || "Unknown Tag"}
                  </span>
                  <button
                    onClick={() => onDeleteTag(tag._id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">No tags available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DiscoverTags;
