import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDiscovers,
  createDiscover,
  deleteDiscover,
  updateDiscover,
  fetchTags,
  createTag,
  deleteTag,
} from "../../../store/discoverApi/discoverThunks";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import AddDiscoverForm from "./AddDiscoverForm";
import DiscoverTags from "./DiscoverTags"; // Import the DiscoverTags component

const AdminDiscovers = () => {
  const dispatch = useDispatch();
  const { discovers, status, error, tags, tagStatus } = useSelector(
    (state) => state.discover
  );
  const [showModal, setShowModal] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false); // For managing tags modal

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    sort: "-createdAt",
  });

  // Fetch discovers and tags on component mount
  useEffect(() => {
    dispatch(fetchDiscovers(pagination));
    dispatch(fetchTags());
  }, [dispatch, pagination]);

  // Handle adding a new discover
  const handleNewDiscover = (newDiscoverData) => {
    dispatch(createDiscover(newDiscoverData)).then((res) => {
      if (res.payload) {
        toast.success("Discover item added successfully!");
        dispatch(fetchDiscovers(pagination));
        setShowModal(false);
      } else {
        toast.error("Failed to add discover item!");
      }
    });
  };

  // Handle deleting a discover
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this discover item?")) {
      dispatch(deleteDiscover(id)).then((res) => {
        if (res.payload) {
          toast.success("Discover item deleted successfully!");
          dispatch(fetchDiscovers(pagination));
        } else {
          toast.error("Failed to delete discover item!");
        }
      });
    }
  };

  // Handle updating discover status
  const handleStatusChange = (id, status) => {
    dispatch(updateDiscover({ id, updateData: { status } })).then((res) => {
      if (res.payload) {
        toast.success(`Discover status updated to "${status}"!`);
        dispatch(fetchDiscovers(pagination));
      } else {
        toast.error("Failed to update discover status!");
      }
    });
  };

  // Render localized text
  const renderLocalizedText = (localizedField, lang = "en") => {
    if (!localizedField?.translations) return "No data available";
    return localizedField?.translations[lang] || "No translation found";
  };

  // Handle tag operations
  const handleAddTag = (newTag) => {
    dispatch(createTag(newTag)).then((res) => {
      if (res.payload) {
        toast.success("Tag added successfully!");
        dispatch(fetchTags());
      } else {
        toast.error("Failed to add tag!");
      }
    });
  };

  const handleDeleteTag = (id) => {
    if (window.confirm("Are you sure you want to delete this tag?")) {
      dispatch(deleteTag(id)).then((res) => {
        if (res.payload) {
          toast.success("Tag deleted successfully!");
          dispatch(fetchTags());
        } else {
          toast.error("Failed to delete tag!");
        }
      });
    }
  };

  return (
    <div className="p-3 sm:p-5">
      <div className="lg:ml-64">
        <div className="rounded-lg dark:border-gray-700 lg:mt-16 mt-0">
          <h3 className="text-gray-900 dark:text-gray-800 mb-3 text-2xl font-bold">
            Discover Items
          </h3>
          <div className="flex justify-between m-5">
            <button
              onClick={() => setShowModal(true)}
              className="block text-white font-medium bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button"
            >
              Add Discover Item
            </button>
            <button
              onClick={() => setShowTagsModal(true)}
              className="block text-white font-medium bg-green-600 hover:bg-green-500 focus:ring-4 focus:outline-none focus:ring-green-300 rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-700 dark:hover:bg-green-600 dark:focus:ring-green-800"
              type="button"
            >
              Manage Tags
            </button>
          </div>

          {status === "loading" && (
            <p className="text-center text-gray-600">Loading discovers...</p>
          )}
          {error && <p className="text-center text-red-500">Error: {error}</p>}

          <div className="grid mt-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
            {discovers?.length > 0 ? (
              discovers.map((discover) => (
                <div
                  key={discover?._id}
                  className="bg-white dark:bg-gray-800 rounded-lg shadow-lg cursor-pointer"
                >
                  <Link to={`/admin/discovers/${discover?._id}`}>
                    <div className="relative">
                      {discover?.image?.[0] ? (
                        <img
                          className="w-full h-45 object-cover object-center rounded-t-lg"
                          src={discover?.image?.[0]}
                          alt={`${renderLocalizedText(discover?.title)} image`}
                        />
                      ) : (
                        <img
                          className="w-full h-45 object-cover object-center rounded-t-lg"
                          src="/placeholder.jpg"
                          alt={`${renderLocalizedText(discover?.title)} image`}
                        />
                      )}
                      <p className="bg-blue-500 text-white text-center px-3 py-1 absolute top-0 right-0">
                        {discover?.countryCode}
                      </p>
                    </div>
                    <div className="p-4">
                      <h3 className="text-md text-gray-900 dark:text-gray-300 font-semibold mb-2">
                        {renderLocalizedText(discover?.title)}
                      </h3>
                      <p className="text-gray-700 dark:text-gray-300 text-sm mb-2">
                        {renderLocalizedText(discover?.description)}
                      </p>
                      <p className="text-gray-700 dark:text-gray-300 text-sm">
                        {discover?.location || "No location specified"}
                      </p>
                      <div className="mt-4">
                        <button
                          onClick={() =>
                            handleStatusChange(discover?._id, "Active")
                          }
                          className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-4 py-2 me-2 mb-2"
                        >
                          Active
                        </button>
                        <button
                          onClick={() =>
                            handleStatusChange(discover?._id, "Inactive")
                          }
                          className="text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-4 py-2 me-2 mb-2"
                        >
                          Inactive
                        </button>
                      </div>
                    </div>
                  </Link>
                  <button
                    onClick={() => handleDelete(discover?._id)}
                    className="text-white w-full mt-4 bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Delete
                  </button>
                </div>
              ))
            ) : (
              <p className="text-center text-gray-600">No discovers found.</p>
            )}
          </div>
        </div>
      </div>

      {showModal && (
        <AddDiscoverForm
          setShowModal={setShowModal}
          onSubmit={handleNewDiscover}
        />
      )}

      {showTagsModal && (
        <DiscoverTags
          tags={tags}
          onAddTag={handleAddTag}
          onDeleteTag={handleDeleteTag}
          setShowModal={setShowTagsModal}
        />
      )}
    </div>
  );
};

export default AdminDiscovers;
