import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

// Fetch all discovers with pagination
export const fetchDiscovers = createAsyncThunk(
  "discover/fetchDiscover",
  async ({ page = 1, limit = 10, sort = "-createdAt" }, { rejectWithValue }) => {
    try {
      const response = await axios.get("/discovers", {
        params: { page, limit, sort },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Fetch a single discover by ID
export const fetchDiscoverById = createAsyncThunk(
  "discover/fetchDiscoverById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/discovers/${id}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Create a new discover
export const createDiscover = createAsyncThunk(
  "discover/createDiscover",
  async (discoverData, { rejectWithValue }) => {
    try {
      const response = await axios.post("/discovers", discoverData);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Update a discover item
export const updateDiscover = createAsyncThunk(
  "discover/updateDiscover",
  async ({ id, updateData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/discovers/${id}`, updateData);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Delete a discover item
export const deleteDiscover = createAsyncThunk(
  "discover/deleteDiscover",
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`/discovers/${id}`);
      return id; // Return the deleted discover ID to update the state
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Search discovers by query, tags, or location
export const searchDiscovers = createAsyncThunk(
  "discover/searchDiscovers",
  async ({ query, tags, location, page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await axios.get("/discovers/search", {
        params: { query, tags, location, page, limit },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Fetch all tags
export const fetchTags = createAsyncThunk(
  "discover/fetchTags",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/discoverTags");
      return response.data.data.tags;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Fetch a single tag by ID
export const fetchTagById = createAsyncThunk(
  "discover/fetchTagById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/discoverTags/${id}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Create a new tag
export const createTag = createAsyncThunk(
  "discover/createTag",
  async (tagData, { rejectWithValue }) => {
    try {
      const response = await axios.post("/discoverTags", tagData);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Update a tag
export const updateTag = createAsyncThunk(
  "discover/updateTag",
  async ({ id, updateData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/discoverTags/${id}`, updateData);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Delete a tag
export const deleteTag = createAsyncThunk(
  "discover/deleteTag",
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`/discoverTags/${id}`);
      return id; // Return the deleted tag ID to update the state
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Search tags by query
export const searchTags = createAsyncThunk(
  "discover/searchTags",
  async ({ query, page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await axios.get("/discoverTags/search", {
        params: { query, page, limit },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
